.DeviceModal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.DeviceModal .preview-image {
    max-height: 200px;
    max-width: 200px;
    margin: auto;
}