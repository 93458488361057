.data-grid-container {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 200px);
    min-height: 400px;
}

.rdg-light {
    flex: 1; /* Umožní DataGridu zabírat celý dostupný prostor */
    height: 100%; /* Nastaví výšku na 100% kontejneru */
}

.row-pending {
background-color: rgba(255, 191, 69, 0.2);
}

.row-in-process {
    background-color: rgba(88, 163, 255, 0.2);
}

.row-completed {
background-color: rgba(93, 255, 69, 0.2);
}

.row-cancelled {
background-color: rgba(136, 136, 136, 0.2);
}

.row-deadline {
    background-color: rgba(255, 0, 0, .35);
}