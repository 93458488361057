.FormFile {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 12px;
}

.FormFile input {
    outline: none;
    border: 1px solid #D0D0D0;
    border-radius: 4px;
}