.selected-repairs {
    max-height: 500px;
    overflow-y: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spin-on-drop {
    animation: spin 0.5s ease-out;
}