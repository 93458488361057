.SetNewPassword h2 {
    margin-bottom: 2rem;
}

.SetNewPassword .container {
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .3);
    background-color: #ffffff;
    max-width: 640px;
    margin: auto;
}

.SetNewPassword .controls {
    display: flex;
    justify-content: end;
}